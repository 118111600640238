<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('coopstudentcertificate')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('coopstudentcertificate')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <!-- Datatable -->
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.student_number"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('name')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('surname')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.surname"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                        <b-form-group :label="$t('program')">
                            <program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                               v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.academic_year"
                            ></academic-years-selectbox>
                        </b-form-group>
                        <b-form-group :label="$t('semester')">
                            <semester-type-selectbox v-model="datatable.queryParams.filter.semester"></semester-type-selectbox>
                        </b-form-group>
                    </b-col>



                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>

            <!-- Certificate Form-->
            <CommonModal ref="updateFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <UpdateForm :student_program_id="student_program_id"
                                :name="names"
                                :surname="surname"
                                @updateFormSuccess="updateFormSuccess"
                                v-if="formProcess=='update'"/>
                </template>
            </CommonModal>



        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header';
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

// Components
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
// Pages
import UpdateForm from './UpdateForm';

// Services
import StudentProgramService from "@/services/StudentProgramService";
// Others
import qs from 'qs';
import CourseCoopAutoComplete from "@/components/interactive-fields/CourseCoopAutoComplete";


export default {
    components: {
        CourseCoopAutoComplete,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        SemesterTypeSelectbox,
        AcademicYearsSelectbox,
        FacultySelectbox,
        ProgramSelectbox,

        UpdateForm
    },
    metaInfo() {
        return {
            title: this.$t('coopstudentcertificate').toUpper()
        };
    },
    data() {
        return {
            student_id:null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('Sertifika Ata'),
                                class: 'ri-edit-box-line',
                                permission: "studentcertificate_store",
                                callback: (row) => {
                                    this.loadData(row.student_id , row.name, row.surname);
                                }
                            },

                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: true,
                    },
                    {
                        label: this.$t('student_id'),
                        field: 'student_id',
                        sortable: false,
                        show:false
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                        sortable: false,
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                        sortable: false,
                    },
                    {
                        label: this.$t('faculty_name'),
                        field: ('faculty_name'),
                        sortable: false,
                    },
                    {
                        label: this.$t('program_name'),
                        field: ('program_name'),
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: null,
            userId: null
        }
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    methods: {
        loadData(student_id,name,surname) {
            this.student_program_id = student_id;
            this.names = name;
            this.surname=surname;
            this.formProcess='update';
            this.$refs.updateFormModal.$refs.commonModal.show();
        },
        filterSet() {
            this.datatable.queryParams.filter = {

            };
        },
        filterClear() {
            this.filterSet();
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return  StudentProgramService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        // Clear
        formClear() {
            this.formId = null;
            this.formProcess = null;
        },
        async updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide();
            this.getRows();
            this.formClear();
        },

    }
}
</script>

